import React, { useState, useRef, useEffect, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TableRecordNotFound,
  LoadingData,
  MyEditor,
} from "../../../components";
import "./setting.css";
import { connect } from "react-redux";
import { userPermissions } from "../../../pages/common/Roles/modules/actions";
import EditIcon from "@mui/icons-material/Edit";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import { fileUpload } from "../../../utilities";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import { FiRefreshCw, FiMove } from "react-icons/fi";
import axios from "axios";
import Slider from "./slider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExportDownload from "./ExportDownload";
import html2canvas from "html2canvas";
import { useScreenshot, createFileName } from "use-react-screenshot";
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  FormControl,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CancelIcon from "@mui/icons-material/Cancel";
import { schedulerColor, PHP_FILE_URL } from "../../../config";
import { sc_image_noavailability as noactvtylog } from "../../../icons";
import moment from "moment";
import ExportPE from "./ExportPE";
import ExportCash from "./ExportCash";
import ExportCsv from "./ExportCsv";
import { payrollSummaryServices } from "./modules/services";
import { fetchData } from "./modules/actions";
import SlickSilder from "./SlickSilder";
import EditTableModal from "./EditTableColumn";
import { alert } from "../../../utilities";
import WarningIconModal from "./WarningIconModal";
import UserListModal from "./UserListModal";
import Checkbox from "@mui/material/Checkbox";
import { history } from "../../../history";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: " 70%",
  width: "74%",
  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  tabletitle: {
    borderBottom: "1px solid #cccccc5c",
    marginLeft: "-15px!important",
    marginRight: "-15px",
    marginBottom: "15px",
    paddingBottom: "5px",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 10px",
    background: "#fff",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    marginLeft: "20px",
    marginRight: "15px",
  },
  topBarResponsive: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  topBarHeading: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  topBarButtons: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  lightclricdiv: {
    minWidth: "37px",
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
    float: "right",
  },
  icdiv: {
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
  },
  textTitle: {
    fontSize: "15px",
    color: "#262626",
    fontWeight: 550,
    margin: "7px 0px 4px",
  },
  hidscroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  Refresh: {
    textTransform: "Capitalize",
    padding: "8px 20px",
    marginLeft: "5px",
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
    color: "white",
    "&:hover": {
      backgroundImage: schedulerColor.main,
    },
  },
  Download: {
    padding: "7px 20px",
    marginLeft: "5px",
    color: "white",
    textTransform: "capitalize",
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      maxHeight: "auto",
      fontSize: "14px",
      padding: "4px 15px",
    },
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#aa076b!important",
    //color: '#ffffff',
    fontWeight: 550,
    textTransform: "capitalize",
    fontSize: "13px",
    lineHeight: "1rem!important",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textareaContainer: {
    position: "relative",
    marginTop: 15,
    marginRight: 15,
  },
  textareacontainertextarea: {
    display: "block",
    width: "100% !important",
    resize: "vertical",
    height: "116px",
    background: "#F3EDED",
  },
  textareacontainerbutton: {
    padding: "5px 16px",
    fontSize: "19px",
    position: "absolute",
    color: "white",
    backgroundImage: schedulerColor.main,
    borderRadius: 4,
    right: "9px",
    bottom: "26px",
    height: 40,
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "white",
  },
  user_name: {
    // maxWidth: "50px",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#323C47",
    fontSize: "12px",
    fontWeight: 600,
    float: "left",
    lineHeight: 3,
  },
  card: {
    padding: "1rem",
    marginBottom: "1rem",
  },
  textBody: {
    color: "#7C7C7C",
    fontWeight: "400",
  },
  TableCell: {
    color: "#707683",
  },
  payrundatess: {
    border: "2px solid black",
  },
});

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: " 35%",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Company = (props) => {
  const [payrollPEData, setPayrollPEData] = useState([]);
  const [myItem, setMyItem] = useState({});
  const [payrollLoading, setPayrollLoading] = useState(false);
  const [commentsData, setCommentsData] = useState("");
  const [correctionWarningData, setCorrectionWarningData] = useState("");
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [payrollCashData, setPayrollCashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("uid");
  const [approvalData, setApprovalData] = useState("");
  const [locationRoleModal, setLocationRoleModal] = useState(false);
  const [usersNameData, setUsersNameData] = useState(null);
  const [activepage, setActivepage] = useState(null);
  const [locationLoader, setLocationLoader] = useState(false);
  const [openNow, setOpenNow] = useState(false);
  const [child_startDate, setChild_startDate] = useState("");
  const [child_endDate, setChild_endDate] = useState("");
  const [getDate, setgetDate] = useState("");
  const [order, setOrder] = useState("asc");
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 3.0,
  });

  useEffect(() => {
    const isPermissionReload =
      Object.keys(props.permissionsData).length > 0 ? false : true;
    props.userPermissions(isPermissionReload);
    getDetailsPayroll();
  }, []);

  const takeScreenShot = async () => {
    const canvas = await html2canvas(ref.current);
    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
    const fileName = "screenshot.png"; // Set the desired filename
    return new File([blob], fileName, { type: "image/png" });
  };

  const onApprovalClick = async (dates, level) => {
    setPayrollLoading(true);
    try {
      const imageFile = await takeScreenShot();

      const formData = new FormData();
      formData.append("myfile", imageFile); // Append the image file to the FormData

      // Append other form data if needed
      formData.append("product_name", "scheduler");

      // Make a POST request with Axios
      const response = await axios.post(PHP_FILE_URL, formData);
      const imageURL = response.data.url;
      if (imageURL) {
        const nameArr = dates.split(",");
        const data = {
          lid: props.lid,
          payroll_startDate: nameArr?.[0],
          payroll_endDate: nameArr?.[1],
          approve_level: level,
          // date: dates,
          // image: imageURL,
        };

        payrollSummaryServices.fetchApproveData(data).then((response) => {
          if (response.data.success === 0) {
            alert.error(response.data.message);
            setApprovalData(response.data.message);
            setPayrollLoading(false);
          } else {
            setApprovalData(response.data.message);
            setPayrollLoading(false);
            alert.success(response.data.message);
            props.getUserDetails(dates, true);
            props.getUpdatedData(props.is_select_card);
            props.getScheduleData();

            if (level === 3) {
              props.getUpdatedData(props.is_select_card);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error during approval process:", error);
      // Handle errors
    }
  };

  const payrollComment = (id, dates) => {
    setPayrollLoading(true);
    var nameArr = dates.split(",");
    var data = {
      activity_id: id,
      comment: commentsData,
      lid: props.lid,
      payroll_startDate: nameArr?.[0],
      payroll_endDate: nameArr?.[1],
    };
    var dataNew = {
      activity_id: null,
      comment: commentsData,
      lid: props.lid,
      payroll_startDate: nameArr?.[0],
      payroll_endDate: nameArr?.[1],
    };

    payrollSummaryServices
      .fetchPayrollComment(id === null ? dataNew : data)
      .then((response) => {
        if (response.data.success === 2) {
          // Handle unsuccessful response
        } else {
          setPayrollLoading(false);
          alert.success(response.data.message);
          props.getUserDetails(dates, true);
          props.fetchDetailsAfterComment(dates);
          setCommentsData("");
        }
      });
  };

  const warningModalOpen = () => {
    setIsWarningModal(true);
  };

  const warningModalClose = () => {
    setIsWarningModal(false);
  };

  const getDetailsPayroll = () => {
    payrollSummaryServices.getDetailsPayrollCash().then((response) => {
      if (response.data.success === 1) {
        setPayrollCashData(response.data?.result);
      }
    });
  };

  const getCorrectionWarning = (dates, uid) => {
    warningModalOpen();
    setIsLoading(true);
    var data = {
      lid: props.lid,
      date: dates,
      uid: uid,
    };
    payrollSummaryServices.warningCorrectionRequest(data).then((response) => {
      if (response.data.success === 1) {
        setCorrectionWarningData(response.data.CorrectionRequest);
        setIsLoading(false);
      }
    });
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onChangeComment = (text) => {
    setCommentsData(text);
    // Update commentsData state
    // Assuming commentsData is also a state variable
    // Update it using whatever method you've used for other state updates
  };

  const getData = (row) => {
    setgetDate(row);
    // Update getDate state
    // Assuming getDate is also a state variable
    // Update it using whatever method you've used for other state updates
  };

  const onRejectClick = (dates, level) => {
    setPayrollLoading(true);
    var nameArr = dates.split(",");
    var data = {
      lid: props.lid,
      payroll_startDate: nameArr?.[0],
      payroll_endDate: nameArr?.[1],
      reject_level: level,
    };
    payrollSummaryServices.rejectApprovalData(data).then((response) => {
      if (response.data.success === 2) {
      } else {
        setApprovalData(response.data.message);
        setPayrollLoading(false);
        alert.error(response.data.message);
        props.getUserDetails(dates, true);
        props.getScheduleData();
      }
    });
  };

  const onClickReset = (dates) => {
    setPayrollLoading(true);
    var nameArr = dates.split(",");
    var data = {
      lid: props.lid,
      payroll_startDate: nameArr?.[0],
      payroll_endDate: nameArr?.[1],
      // date: dates,
    };
    payrollSummaryServices.resetApprovalData(data).then((response) => {
      if (response.data.success === 2) {
      } else {
        setApprovalData(response.data.message);
        setPayrollLoading(false);
        alert.success(response.data.message);
        props.getUserDetails(dates, true);
        props.getScheduleData();
      }
    });
  };

  const openRoleLocation = (row, value) => {
    setUsersNameData(row);
    setActivepage(value);
    setLocationRoleModal(true);
    setLocationLoader(true);
  };

  const closelocationroleModal = () => {
    setLocationRoleModal(false);
  };

  const handleCollapse = () => {
    setOpenNow(!openNow);
  };
  const {
    reportOpen,
    onReportAccordianChanged,
    payrollLoadingg,
    classes,
    pageNo,
    rowsPerPage,
    permissionsData,
    LocationDetaildata,
    Activity_log,
    AprroveData,
  } = props;

  const totalIssues = props.payrollData?.total_issues ?? 0;
  const resolvedIssues = props.payrollData?.resolved_issues ?? 0;
  const result = totalIssues === 0 ? 0 : totalIssues - resolvedIssues;

  var approval_one;
  var approval_two;
  var approval_three;
  var isShowButton_one;
  var isShowButton_two;
  var isShowButton_three;
  var approval1Image;
  var approval2Image;
  var approval3Image;
  var approval_name;
  var approval_name_two;
  var approval_name_three;
  var userCountLevel1;
  var userCountLevel2;
  var userCountLevel3;
  var user_list1;
  var user_list2;
  var user_list3;
  var dates = props.date;

  AprroveData &&
    AprroveData.map((item) => {
      if (item.approve_payroll_level === 1) {
        approval1Image = item.image;
        approval_one = item.approve_payroll_level_status;
        approval_name = item?.approved_by_uid?.user_name;
        isShowButton_one = item?.isShowButton;
        userCountLevel1 = item?.level_count;
        user_list1 = item?.level_users;
      }
      if (item.approve_payroll_level === 2) {
        approval2Image = item.image;
        approval_two = item.approve_payroll_level_status;
        approval_name_two = item?.approved_by_uid?.user_name;
        isShowButton_two = item?.isShowButton;
        userCountLevel2 = item?.level_count;
        user_list2 = item?.level_users;
      }
      if (item.approve_payroll_level === 3) {
        approval3Image = item.image;
        approval_three = item.approve_payroll_level_status;
        approval_name_three = item?.approved_by_uid?.user_name;
        isShowButton_three = item?.isShowButton;
        userCountLevel3 = item?.level_count;
        user_list3 = item?.level_users;
      }
    });
  // Define other functions here
  if (payrollLoadingg || payrollLoading) return <LoadingData />;
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Grid item xs={8} style={{ float: "right" }}>
          <div style={{ display: "flex" }}>
            {/* <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.Employechecked === 1 ? true : false}
                                    onChange={props.handleChangeCheckBox}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                            label="Hide employees with 0 Hours" />
                    </FormGroup> */}
          </div>

          <Button className={classes.Download} style={{ display: "none" }}>
            Pay Minimum
          </Button>
        </Grid>
      </Grid>
      <div className={`${classes.topBar} ${classes.topBarResponsive}`}>
        <Grid
          item
          // style={{ margin: "0px 10px -38px 0px" }}
          className={classes.topBarHeading}
        >
          <Grid container justifyContent="flex-start">
            <span
              style={{
                color: "#AA076B",
                fontWeight: "500",
                fontSize: "17px",
                marginLeft: "15px",
              }}
            >
              Payrun Period
            </span>
          </Grid>
        </Grid>
        <Grid item style={{ display: "contents" }}>
          <Grid
            container
            style={{
              alignItems: "center",
              gap: "5px",
            }}
            className={classes.topBarButtons}
          >
            {/* {payrollLoading || props.payrollLoadingCard || props.isLoading || props.AprroveData?.length === 0 ? "" :
                        <div style={{ marginRight: "9px" }}>
                            Last refreshed- {moment(refreshTime).locale('en-US').format("ddd MMM DD , hh:mm A")}
                        </div> } */}

            {permissionsData?.scheduler_payroll_reset_data_and_approvals ? (
              <div>
                <Button
                  onClick={() => onClickReset(dates)}
                  className={classes.Download}
                >
                  Reset Data and Approvals
                </Button>
                {/* <FiRefreshCw className={classes.iccolor} /> */}
              </div>
            ) : (
              ""
            )}

            {props.items && (
              <ExportCsv
                locationName={"locationName"}
                printDate={
                  moment(child_startDate).locale("en-US").format("ddd MMM DD") +
                  "to" +
                  moment(child_endDate).locale("en-US").format("ddd MMM DD")
                }
                rowData={props.usersData}
                items={props.items && props.items}
              />
            )}

            <ExportCash
              rowData={payrollCashData}
              lid={props.lid}
              dates={props.dates}
              date={props.date}
              valueSeclect={props.valueSeclect}
            />

            {/* <div className={classes.icdiv} onClick={props.printModalOpen}>
      <FiPrinter
        className={classes.iccolor}
      />
    </div> */}

            {/* btn */}
          </Grid>
        </Grid>
      </div>
      <Box
        className={classes.hidscroll}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          width: "100%",
        }}
      >
        <Slider
          className={classes.payrundatess}
          is_select_card={props.is_select_card}
          clickCard={props.clickCard}
          getData={getData}
          sliderData={props.MonthData}
          showNewCard={props.showNewCard}
        />
      </Box>

      <Grid item xs={12} className="payrollMain">
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.tabletitle}>
              <span
                style={{
                  color: "rgb(170, 7, 107)",
                  fontWeight: "500",
                  fontSize: "17px",
                  marginLeft: "15px",
                }}
              >
                Summary
              </span>
            </div>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              <Grid
                item
                xs={8 / 12}
                style={{
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Calendar Days
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "rgb(170 7 107)",
                    fontWeight: "500",
                    textAlign: "center",
                    padding: "15px",
                  }}
                >
                  {props.payrollData?.calendar_days} Days
                </Typography>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                onClick={() => history.push(`/hr/payroll`)}
                style={{
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Unmapped Employees
                </Typography>

                <Link>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "12px",
                      color: "rgb(170 7 107)",
                      fontWeight: "500",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    {props.payrollData?.unmapped_users}
                  </Typography>
                </Link>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Employees
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "rgb(170 7 107)",
                    fontWeight: "500",
                    textAlign: "center",
                    padding: "15px",
                  }}
                >
                  {props.payrollData?.employees}
                </Typography>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Total Paid hours
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "rgb(170 7 107)",
                    fontWeight: "500",
                    textAlign: "center",
                    padding: "15px",
                  }}
                >
                  {props.payrollData?.total_paid_hours}
                </Typography>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Correction Request
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "rgb(170 7 107)",
                    fontWeight: "500",
                    textAlign: "center",
                    padding: "15px",
                  }}
                >
                  {props.payrollData?.resolved_correction_requests} /{" "}
                  {props.payrollData?.correction_requests}{" "}
                  <WarningIcon style={{ color: "#fea400" }} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  minWidth: "120px",
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Hours Summary
                </Typography>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Type
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Hours
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      User
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      cash
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.total_cash_hours != null
                        ? 0
                        : props.payrollData?.total_cash_hours}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.employees != null
                        ? 0
                        : props.payrollData?.employees}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  minWidth: "190px",
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Flags Summary
                </Typography>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Type
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Pending
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Ignored
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      break
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.total_issues ?? 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {result}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.resolved_issues ?? 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={8 / 12}
                style={{
                  minWidth: "120px",
                  padding: "",
                  border: "1px solid rgb(170, 7, 107)",
                  color: "rgb(170, 7, 107)",
                  background: "white",
                  marginRight: "10px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    color: "rgb(170, 7, 107)",
                    background: "rgba(170, 7, 107, 0.125)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Public Holidays
                </Typography>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Count
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "rgb(170, 7, 107)",
                        background: "rgba(170, 7, 107, 0.125)",
                        fontWeight: 700,
                      }}
                    >
                      Hours
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.public_holidays_count}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.holiday_user_count}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        fontWeight: 500,
                      }}
                    >
                      {props.payrollData?.public_holidays_hrs}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.topBar}
        style={{
          marginTop: "20px",
          borderBottom: "1px solid #cccccc5c",
          paddingBottom: "5px",
          paddingTop: "5px",
          paddingLeft: "0px",
          paddingRight: "20px",
        }}
      >
        <Grid item xl={5} lg={5} sm={5} xs={12}>
          <Grid container justifyContent="flex-start">
            <span
              style={{
                color: "#AA076B",
                fontWeight: "500",
                fontSize: "17px",
                marginLeft: "15px",
              }}
            >
              Approvals
            </span>
          </Grid>
        </Grid>
        <Grid item xl={7} lg={7} sm={7} xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            style={{
              alignItems: "center",
            }}
          >
            <Grid item>
              {/* {permissionsData?.level_1_approval === 1 && permissionsData.payrun_reset ?
                            <Button className="inst-btns" onClick={() => onClickReset(dates)}>Reset</Button>
                            : ""} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.resetloader ? (
        <LoadingData />
      ) : (
        <Grid item xs={12} className="approvalMain" alignItems="center">
          {LocationDetaildata?.payrun_1_level_approval === 1 ? (
            <Grid item>
              <div className="approvalIcon">
                {permissionsData.level_1_approval ? (
                  isShowButton_one === 1 ? (
                    props?.usersData.length > 0 ? (
                      <>
                        <CheckCircleIcon
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => onApprovalClick(dates, 1)}
                        />
                        {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => onRejectClick(dates, 1)} /> */}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <Grid
                item
                className={`${
                  LocationDetaildata?.payrun_2_level_approval === 1
                    ? "wrapper"
                    : ""
                }`}
              >
                <Button
                  className="inst-btn"
                  style={{
                    backgroundColor:
                      approval_one === 1
                        ? "#0F640D"
                        : approval_one === 2
                        ? "#b71c1c"
                        : "rgba(170, 7, 107, 0.125)",
                    color:
                      approval_one === 1
                        ? "white"
                        : approval_one === 2
                        ? "white"
                        : "#263238",
                  }}
                >
                  {isShowButton_one === 1
                    ? "Level 1 Approval"
                    : "Level 1 Approved"}
                </Button>
              </Grid>
              {/* {LocationDetaildata?.payrun_1_level_approval === 1 ? */}
              <p style={{ fontSize: "14px" }}>
                {`${
                  approval_one === 1
                    ? "Approved By " + approval_name
                    : approval_one === 2
                    ? "Rejected By " + approval_name
                    : ""
                }`}
                {approval_one === 1 && approval1Image ? (
                  <a
                    href={
                      process.env.REACT_APP_DIGITAL_OCEAN_SPACE_URL +
                      approval1Image
                    }
                  >
                    <DownloadForOfflineIcon
                      style={{
                        color: "linear-gradient(45deg, #AA076B, #9F0D9C)",
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
              </p>

              <p
                style={{
                  height: "26px",
                  cursor: "pointer",
                  textAlign: "left",
                  color: schedulerColor.main,
                  margin: "8px",
                  marginLeft: "44px",
                }}
                onClick={() => openRoleLocation(user_list1)}
              >{`${"User " + userCountLevel1}`}</p>
            </Grid>
          ) : (
            ""
          )}

          {LocationDetaildata?.payrun_2_level_approval === 1 ? (
            <Grid item>
              <div className="approvalIcon">
                {permissionsData.level_2_approval ? (
                  isShowButton_two === 1 ? (
                    props?.usersData.length > 0 ? (
                      <>
                        <CheckCircleIcon
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => onApprovalClick(dates, 2)}
                        />
                        {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => onRejectClick(dates, 2)} /> */}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <Grid
                item
                className={`${
                  LocationDetaildata?.payrun_3_level_approval === 1
                    ? "wrapper"
                    : ""
                }`}
              >
                <Button
                  className="inst-btn"
                  style={{
                    backgroundColor:
                      approval_two === 1
                        ? "#0F640D"
                        : approval_two === 2
                        ? "#b71c1c"
                        : "rgba(170, 7, 107, 0.125)",
                    color:
                      approval_two === 1
                        ? "white"
                        : approval_two === 2
                        ? "white"
                        : "#263238",
                  }}
                >
                  {isShowButton_two === 1
                    ? "Level 2 Approval"
                    : "Level 2 Approved"}
                </Button>
              </Grid>
              {/* {LocationDetaildata?.payrun_2_level_approval === 2 ? */}
              <p style={{ fontSize: "14px" }}>
                {`${
                  approval_two === 1
                    ? "Approved By " + approval_name_two
                    : approval_two === 2
                    ? "Rejected By " + approval_name_two
                    : ""
                }`}
                {approval_two === 1 && approval2Image ? (
                  <a
                    href={
                      process.env.REACT_APP_DIGITAL_OCEAN_SPACE_URL +
                      approval2Image
                    }
                  >
                    <DownloadForOfflineIcon
                      style={{
                        color: "linear-gradient(45deg, #AA076B, #9F0D9C)",
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
              </p>
              <p
                style={{
                  height: "26px",
                  cursor: "pointer",
                  textAlign: "left",
                  color: schedulerColor.main,
                  margin: "8px",
                  marginLeft: "44px",
                }}
                onClick={() => openRoleLocation(user_list2)}
              >{`${"User " + userCountLevel2}`}</p>
            </Grid>
          ) : (
            ""
          )}
          {LocationDetaildata?.payrun_3_level_approval ? (
            <Grid item>
              <div className="approvalIcon">
                {permissionsData.level_3_approval ? (
                  isShowButton_three === 1 ? (
                    props?.usersData.length > 0 ? (
                      <>
                        <CheckCircleIcon
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => onApprovalClick(dates, 3)}
                        />
                        {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => onRejectClick(dates, 3)} /> */}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <Grid item>
                <Button
                  className="inst-btn"
                  style={{
                    backgroundColor:
                      approval_three === 1
                        ? "#0F640D"
                        : approval_three === 2
                        ? "#b71c1c"
                        : "rgba(170, 7, 107, 0.125)",
                    color:
                      approval_three === 1
                        ? "white"
                        : approval_three === 2
                        ? "white"
                        : "#263238",
                  }}
                >
                  {isShowButton_three === 1
                    ? "Level 3 Approval"
                    : "Level 3 Approved"}
                </Button>
              </Grid>

              <p style={{ fontSize: "14px" }}>
                {`${
                  approval_three === 1
                    ? "Approved By " + approval_name_three
                    : approval_three === 2
                    ? "Rejected By " + approval_name_three
                    : ""
                }`}
                {approval_three === 1 && approval3Image ? (
                  <a
                    href={
                      process.env.REACT_APP_DIGITAL_OCEAN_SPACE_URL +
                      approval3Image
                    }
                  >
                    <DownloadForOfflineIcon
                      style={{
                        color: "linear-gradient(45deg, #AA076B, #9F0D9C)",
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
              </p>
              <p
                style={{
                  height: "26px",
                  cursor: "pointer",
                  textAlign: "center",
                  color: schedulerColor.main,
                  margin: "8px",
                }}
                onClick={() => openRoleLocation(user_list3)}
              >
                {`${"User " + userCountLevel3}`}
              </p>
            </Grid>
          ) : (
            ""
          )}
          {/* <Grid item style={{ marginLeft: "12px", marginBottom: "22px" }} >
                {LocationDetaildata?.payrun_1_level_approval === 1 &&
                    <Button className="inst-btns" onClick={() => onClickReset(dates)}>Reset</Button>
                }
            </Grid> */}
        </Grid>
      )}
      <Accordion
        expanded={reportOpen}
        onChange={onReportAccordianChanged}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          borderRadius: "5px",
          marginTop: "20px",
          overflow: "hidden",
          marginLeft: "20px",
          marginRight: "15px",
          boxSizing: "border-box",
        }}
      >
        <AccordionSummary
          style={{ borderBottom: "1px solid rgba(204, 204, 204, 0.36)" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}></Typography>
          <span
            style={{
              color: "#AA076B",
              fontWeight: "500",
              fontSize: "17px",
            }}
          >
            Report
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: "hidden", width: "100%" }}>
          {/* <Grid item sm={12} style={{ overflow: "scroll" }}> */}
          <div ref={ref}>
            <Paper style={{ marginTop: 10 }}>
              <div style={{ margin: "0 20px 20px 20px" }}>
                <Tooltip arrow title="Edit" className="editIcon">
                  <IconButton size="small" onClick={props.openEditTableModal}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <TableContainer
              // onClick={handleCollapse}
              >
                <Table className={classes.table} size="medium">
                  <TableHead style={{ background: "rgba(170, 7, 107, 0.125)" }}>
                    <TableRow>
                      {/* <TableCell className={classes.headCell}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                       
                                    >
                                        {openNow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell> */}
                      {props.items?.map((headCell) =>
                        headCell.status === true || headCell.status === 1 ? (
                          <TableCell
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            <TableSortLabel
                              className={classes.headCell}
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={createSortHandler(headCell.id)}
                            >
                              {headCell.content}
                              {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </span>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ) : (
                          ""
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  {
                    payrollLoading || props.payrollLoadingCard ? (
                      //  <Collapse in={openNow} timeout="auto" unmountOnExit>
                      <TableBody>
                        <LoadingData />
                      </TableBody>
                    ) : (
                      // {/* </Collapse> */}
                      // <Collapse  colSpan={12} fullWidth in={openNow} timeout="auto" unmountOnExit>
                      <TableBody>
                        {props?.usersData?.map((row, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={row.uid}>
                              {props?.items?.map((childSeq) => {
                                let cellContent;

                                if (childSeq.status) {
                                  switch (childSeq.content) {
                                    case "Username":
                                      cellContent = (
                                        <Typography
                                          className={classes.user_name}
                                          style={{ color: "#323C47" }}
                                        >
                                          {row.user_name || "-"}
                                        </Typography>
                                      );
                                      break;
                                    case "Role":
                                      cellContent = row.user_role || "-";
                                      break;
                                    case "Template Name":
                                      cellContent = row.template_name || "-";
                                      break;
                                    case "Payroll Rule":
                                      cellContent = row.payroll_rule || "-";
                                      break;
                                    case "Public Holiday":
                                      cellContent = row.isconected_holiday ? (
                                        <div style={{ color: "green" }}>
                                          Yes
                                        </div>
                                      ) : (
                                        <div style={{ color: "red" }}>No</div>
                                      );
                                      break;
                                    case "Employee ID":
                                      cellContent = row.mapped_code || "-";
                                      break;
                                    case "Scheduled Hrs":
                                      cellContent =
                                        row.total_scheduled_hrs || "-";
                                      break;
                                    case "Actual Hours":
                                      cellContent = row.total_actual_hrs || "-";
                                      break;
                                    case "Paid Hours":
                                      cellContent = row.total_paid_hrs || "-";
                                      break;
                                    case "Unsch hours":
                                      cellContent = row.unsch_hrs || "-";
                                      break;
                                    case "Break diff":
                                      cellContent = row.total_break_hrs || "-";
                                      break;
                                    case "Estimated Wages":
                                      cellContent = row.total_est_wages
                                        ? `$${row.total_est_wages}`
                                        : "-";
                                      break;
                                    case "Manual hours":
                                      cellContent = row.manual_hours || "-";
                                      break;
                                    case "Correction Req":
                                      cellContent =
                                        row.correction_request !== 0 ? (
                                          <>
                                            {row.correction_request}
                                            <WarningIcon
                                              style={{
                                                color: "#fea400",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                getCorrectionWarning(
                                                  dates,
                                                  row.uid
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          row.correction_request || "-"
                                        );
                                      break;
                                    case "OverTime Hours":
                                      cellContent = row.overtime_hours || "-";
                                      break;
                                    case "Regular Hrs":
                                      cellContent = row.regular_hours || "-";
                                      break;
                                    case "Holiday Hrs":
                                      cellContent = row.holiday_hours || "-";
                                      break;
                                    default:
                                      if (
                                        row[
                                          `${childSeq.content?.replaceAll(
                                            " ",
                                            "_"
                                          )}_total_hrs`
                                        ]
                                      ) {
                                        cellContent = row[
                                          `${childSeq.content?.replaceAll(
                                            " ",
                                            "_"
                                          )}_total_hrs`
                                        ]
                                          ? `${
                                              row[
                                                `${childSeq.content?.replaceAll(
                                                  " ",
                                                  "_"
                                                )}_total_hrs`
                                              ]
                                            }`
                                          : "-";
                                      } else if (
                                        `${childSeq.content?.replaceAll(
                                          " ",
                                          "_"
                                        )}_total_hrs`
                                      ) {
                                        cellContent =
                                          `${childSeq.content?.replaceAll(
                                            " ",
                                            "_"
                                          )}_total_hrs` in row &&
                                          row[
                                            `${childSeq.content?.replaceAll(
                                              " ",
                                              "_"
                                            )}_total_hrs`
                                          ]
                                            ? row[
                                                `${childSeq.content?.replaceAll(
                                                  " ",
                                                  "_"
                                                )}_total_hrs`
                                              ]
                                            : "-";
                                      } else {
                                        if (
                                          row[
                                            `${childSeq.content?.replaceAll(
                                              " ",
                                              "_"
                                            )}_total_hrs`
                                          ] == ""
                                        ) {
                                          cellContent =
                                            row[
                                              `${childSeq.content?.replaceAll(
                                                " ",
                                                "_"
                                              )}_total_hrs`
                                            ] || "-";
                                        }
                                      }
                                      break;
                                  }
                                }

                                return cellContent ? (
                                  <TableCell
                                    align="left"
                                    className={classes.TableCell}
                                    key={childSeq.content}
                                  >
                                    {cellContent}
                                  </TableCell>
                                ) : (
                                  <></>
                                );
                              })}
                            </TableRow>
                          );
                        })}

                        {/* {props.usersData.map((row, index) => {
                                                        return (
                                                            <TableRow hover tabIndex={-1} key={row.uid}>
                                                                {
                                                                    props.items?.map((childSeq) => (
                                                                        // childSeq.status === true ?
                                                                        childSeq.content === "Username" ?
                                                                            <TableCell align="left"
                                                                                className={classes.TableCell}>
                                                                                <Typography className={classes.user_name}
                                                                                    style={{ color: '#323C47' }} >
                                                                                    {row.user_name}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            : childSeq.pi_id &&
                                                                                childSeq.content ? <TableCell>
                                                                                {row[`${childSeq.content.replaceAll(" ", "_")}_total_hrs`] ? row[`${childSeq.content.replaceAll(" ", "_")}_total_hrs`] : "0.00"}

                                                                            </TableCell>
                                                                                : childSeq.content === "Role" ? <TableCell align="left" className={classes.TableCell}>
                                                                                    {row.user_role}
                                                                                </TableCell>
                                                                                    : childSeq.content === "Template Name" ? <TableCell align="left" className={classes.TableCell}>
                                                                                        {row.template_name}
                                                                                    </TableCell>
                                                                                        : childSeq.content === "Payroll Rule" ? <TableCell align="left" className={classes.TableCell}  >
                                                                                            {row.payroll_rule}
                                                                                        </TableCell>
                                                                                            : childSeq.content === "Public Holiday" ? <TableCell align="left" className={classes.TableCell}  >
                                                                                                {row.isconected_holiday ? <div style={{ color: "green" }}>Yes</div> : <div style={{ color: "red" }}>No</div>}
                                                                                            </TableCell>
                                                                                                : childSeq.content === "Employee ID" ? <TableCell align="left" className={classes.TableCell}  >
                                                                                                    {row.mapped_code}
                                                                                                </TableCell>
                                                                                                    : childSeq.content === "Scheduled Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                        {row.total_scheduled_hrs}
                                                                                                    </TableCell>
                                                                                                        : childSeq.content === "Actual Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                            {row.total_actual_hrs}
                                                                                                        </TableCell>
                                                                                                            : childSeq.content === "Paid Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                {row.total_paid_hrs}
                                                                                                            </TableCell>
                                                                                                                : childSeq.content === "Unsch hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                    {row.unsch_hrs}
                                                                                                                </TableCell>
                                                                                                                    : childSeq.content === "Break diff" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                        {row.total_break_hrs}
                                                                                                                    </TableCell>
                                                                                                                        : childSeq.content === "Estimated Wages" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                            ${row.total_est_wages}
                                                                                                                        </TableCell>
                                                                                                                            : childSeq.content === "Manual hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                                {row.manual_hours}
                                                                                                                            </TableCell>
                                                                                                                                : childSeq.content === "Correction Req" ? <TableCell align="left" className={classes.TableCell} styel={{ textDecoration: "underline" }}>
                                                                                                                                    {row.correction_request !== 0 ?
                                                                                                                                        <>  {row.correction_request}
                                                                                                                                            <WarningIcon style={{ color: '#fea400', cursor: 'pointer' }}
                                                                                                                                                onClick={() => getCorrectionWarning(dates, row.uid)} /> </>
                                                                                                                                        : row.correction_request}
                                                                                                                                </TableCell>
                                                                                                                                    : childSeq.content === "OverTime Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                                        {row.overtime_hours}
                                                                                                                                    </TableCell>
                                                                                                                                        : childSeq.content === "Regular Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                                            {row.regular_hours}
                                                                                                                                        </TableCell>
                                                                                                                                            : childSeq.content === "Holiday Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                                                {row.holiday_hours}
                                                                                                                                            </TableCell>
                                                                                                                                                :
                                                                                                                                                <TableCell align="left" className={classes.TableCell}>
                                                                                                                                                    -
                                                                                                                                                </TableCell>
                                                                        //  : ""
                                                                    ))
                                                                }
                                                            </TableRow>

                                                        )


                                                    })} */}

                        {props.usersData && props.usersData.length < 1 ? (
                          <TableRecordNotFound
                            colSpan={8}
                            label="No Data Found.."
                          />
                        ) : (
                          ""
                        )}
                      </TableBody>
                    )
                    // </Collapse>
                  }
                </Table>
              </TableContainer>
            </Paper>
          </div>
          {/* </Grid> */}
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          width: "100%",
          display: "grid",
          marginLeft: "20px",
          marginRight: "15px",
          overflowY: "scroll",
          borderRadius: "5px",
          marginTop: "20px",
          marginBottom: "50px",
        }}
      >
        <AccordionSummary
          style={{ borderBottom: "1px solid rgba(204, 204, 204, 0.36)" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span
            style={{
              color: "#AA076B",
              fontWeight: "500",
              fontSize: "17px",
            }}
          >
            Activity Log{" "}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {props.payrollLoadingCard ? (
            <LoadingData />
          ) : Activity_log ? (
            Activity_log.map((item, index) => {
              return (
                <Grid item xs={12} className="ActiveLogMain">
                  <Grid item xs={2} md={2} lg={2} sm={2}>
                    <Button className="Account-btn">
                      {moment(item.payroll_approve_date)
                        .locale("en-US")
                        .format("DD-MMM-YYYY hh:mm a")}
                    </Button>

                    {index < Activity_log.length - 1 && (
                      <div className="ActiveverticalLine" />
                    )}
                    {/* <Button className="Account-btn">{moment(item.payroll_approve_date).locale('en-US').format("DD-MMM-YYYY")}</Button> */}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    md={10}
                    lg={10}
                    sm={10}
                    style={{ marginTop: "-17px" }}
                  >
                    <div
                      className="ActiveTitle"
                      style={{ marginBottom: "-23px" }}
                    >
                      {item.approved_user}
                      {/* @ {moment(item.payroll_approve_date).locale('en-US').format("hh:mm a")} */}
                    </div>
                    <div className="ActiveTitle">
                      Status Changed : {item.status}
                    </div>
                    <p className="ActiveComment">
                      Comment :
                      <Typography
                        className="ActiveComment"
                        style={{ width: "20%" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.Comment &&
                            item.Comment.map(
                              (it) =>
                                `<div class="comment-content">${it.comment}</div>`
                            ).join(""),
                        }}
                      ></Typography>
                    </p>

                    {item.isallowcomment === 1 ? (
                      <div className={classes.textareaContainer}>
                        <MyEditor
                          //  key={item._id}
                          value={commentsData}
                          handleEditor={(e) => onChangeComment(e)}
                        />
                        <button
                          className={classes.textareacontainerbutton}
                          onClick={() => payrollComment(item?._id, dates)}
                        >
                          Send
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              );
            })
          ) : (
            ""
          )}
        </AccordionDetails>
      </Accordion>

      {isWarningModal === true && (
        <WarningIconModal
          is_warning_modal={isWarningModal}
          warningModalClose={warningModalClose}
          isLoading={isLoading}
          correctionWarningData={correctionWarningData}
        />
      )}

      {props.EditTableModal === true && (
        <EditTableModal
          closeEditTableModal={props.closeEditTableModal}
          EditTableModal={props.EditTableModal}
          onDragEnd={props.onDragEnd}
          reportsHeaderLoader={props.reportsHeaderLoader}
          items={props.items}
          saveCloumnRow={props.saveCloumnRow}
          handleChangeChecked={props.handleChangeChecked}
        />
      )}

      <Modal
        open={locationRoleModal}
        onClose={closelocationroleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UserListModal
            AprroveData={AprroveData}
            user_list={usersNameData}
            pageNo={pageNo}
            rowsPerPage={rowsPerPage}
          />
        </Box>
      </Modal>

      <Modal open={props.downloadModal} onClose={props.closeDownloadModal}>
        <Box sx={styleModal}>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ display: "flex" }}>
              <Grid item md={6}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h6"
                  style={{ fontWeight: 700 }}
                >
                  Payment Method :
                </Typography>
              </Grid>
              <Grid item md={6}>
                <FormControl
                  sx={{ m: 1, minWidth: 120, marginTop: "auto" }}
                  size="small"
                  error
                >
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={props.valueSeclect}
                    label="Download123"
                    style={{
                      width: "200px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}
                    onChange={props.handleChange}
                  >
                    {payrollCashData &&
                      payrollCashData.map((row) => {
                        return (
                          <MenuItem
                            value={row}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {row.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.show} style={{ marginTop: -10 }}>
                <Checkbox
                  name="work_date"
                  //value={displayData.display[0]?.showOnHomePage}
                  checked={props.dates.work_date}
                  onChange={(e) => props.handleChangeShowMenu(e)}
                  className={classes.discussion}
                />{" "}
                Work Date
              </Typography>
            </Grid>

            <Grid item md={12}>
              <Typography className={classes.show} style={{ marginTop: -10 }}>
                <Checkbox
                  name="period_start"
                  //value={displayData.display[0]?.showOnHomePage}
                  checked={props.dates.period_start}
                  onChange={(e) => props.handleChangeShowMenu(e)}
                  className={classes.discussion}
                />{" "}
                Period start/End
              </Typography>
            </Grid>

            <Grid item md={12} style={{ textAlign: "center" }}>
              <ExportDownload
                rowData={props.downloaduserdata}
                lid={props.lid}
                dates={props.dates}
                date={props.date}
                valueSeclect={props.valueSeclect}
                closeDownloadModal={props.closeDownloadModal}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
  // Your JSX here
};

const mapStateToProps = (state) => ({
  permissionsData: state.settingsRoles.permissionsData,
  LocationDetaildata: state.schedulerPayrollSummary.LocationDetaildata,
});

const ConnectWith = connect(mapStateToProps, { userPermissions, fetchData })(
  Company
);
export default withStyles(styles)(ConnectWith);
