import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DragIndicator as Hamburger } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress, FormControlLabel } from "@material-ui/core";

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderBottom: "2px solid #C4C4C4",
  display: "flex",
  // change background colour if dragging
  background: isDragging ? "#C4C4C4" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class EditTableColumn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { closeEditTableModal, EditTableModal, onDragEnd, items } =
      this.props;
    return (
      <div>
        <BootstrapDialog
          onClose={closeEditTableModal}
          aria-labelledby="customized-dialog-title"
          open={EditTableModal}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeEditTableModal}
          >
            Rearrange Columns
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom className="paraEdit">
              Rearrange and enable/disable the columns as per your requirement
            </Typography>
            <div className="titleColumn">Column Name</div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items &&
                      items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <IconButton>
                                <Hamburger
                                  // onMouseEnter={this.props.handleCloseExpend}
                                  style={{ color: "black", cursor: "move" }}
                                />
                              </IconButton>
                              <Typography className="titlecloumnName">
                                {item.content}
                              </Typography>
                              <div className="ms-auto">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="status"
                                      checked={item.status ? true : false}
                                      onChange={(e) =>
                                        this.props.handleChangeChecked(
                                          e,
                                          item.id,
                                          index
                                        )
                                      }
                                    />
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              startIcon={
                this.props.reportsHeaderLoader && (
                  <CircularProgress size={16} color="inherit" />
                )
              }
              disabled={this.props.reportsHeaderLoader}
              onClick={() => this.props.saveCloumnRow(this.props.items)}
              className="editSaveButton"
            >
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    );
  }
}

export default EditTableColumn;
