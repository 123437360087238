//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    float: "left",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "18px",
    marginTop: "9px",
  },
  closeIcon: {
    padding: "10px 22px",
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
  },
  dialogContent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  saveButton: {
    color: "white",
    padding: "10px 22px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class MyRightModal extends Component {
  renderLoader = () => {
    const { classes } = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  };

  renderContent = () => {
    const {
      classes,
      children,
      data,
      clockInShift,
      clockoutShift,
      popoverOpen,
      bgColor,
      popoverId,
    } = this.props;

    return (
      <>
        <DialogContent className={classes.dialogContent} dividers>
          {children}
        </DialogContent>
        {data?.isPublished && (
          <DialogActions style={{ justifyContent: "center" }}>
            {data?.clock_in && data?.clock_out ? (
              ""
            ) : (
              <Button
                variant="outlined"
                size="medium"
                onClick={clockInShift}
                style={{
                  backgroundImage: bgColor ? bgColor : "gray",
                  fontWeight: 700,
                  fontStyle: "initial",
                  textTransform: "capitalize",
                  backgroundColor: bgColor ? bgColor : "gray",
                  fontWeight: 700,
                  fontStyle: "initial",
                }}
                className={classes.saveButton}
              >
                {data?.clock_in && data?.break_start === null
                  ? "Take Break"
                  : data?.break_start && data?.clock_in
                  ? "End Break"
                  : "Clock In"}
              </Button>
            )}

            {data?.break_start && data?.clock_in ? (
              ""
            ) : data?.clock_in && data?.clock_out ? (
              ""
            ) : (
              <Button
                aria-describedby={popoverId}
                style={{
                  backgroundColor: "rgba(159, 13, 156, 0.25)",
                  fontWeight: 700,
                  fontStyle: "initial",
                  color: "rgba(170, 7, 107, 1)",
                  fontWeight: 700,
                  fontStyle: "initial",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                size="medium"
                className={classes.saveButton}
                onClick={
                  data?.clock_in && data?.break_start === null
                    ? clockoutShift
                    : popoverOpen
                }
              >
                {data?.clock_in && data?.break_start === null
                  ? "Clock Out"
                  : "Can't Work"}
              </Button>
            )}
          </DialogActions>
        )}
      </>
    );
  };

  render() {
    const { classes, open, onClose, title, bgColor, loading } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={open || false}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            backgroundColor: bgColor || "gray",
            backgroundImage: bgColor || "gray",
            fontWeight: 700,
            fontStyle: "initial",
          }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4">
            {title}
          </Typography>
          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        {loading ? this.renderLoader() : this.renderContent()}
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyRightModal);
