import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, LoadingData } from "../../../components";
import { fetchData, getScheduleData } from "./modules/actions";
import { onChangePage } from "../../../components/modules/actions";
// import { getScheduleData } from "../Settings/modules/actions";
import { payrollSummaryServices } from "./modules/services";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import DetailPayroll from "./DetailPayroll";
import ExportDownload from "./ExportDownload";
import { alert } from "../../../utilities";
import moment from "moment";
import { history } from "../../../history";
import { addMonths } from "date-fns";
import { daysToWeeks } from "date-fns/esm/fp";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export function fetchPayrollSummaryData() {
  window.PayrollSummary.getScheduleData();
  window.PayrollSummary.getHeaderData();
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class PayrollSummary extends Component {
  constructor(props) {
    super(props);
    window.PayrollSummary = this;
    this.state = {
      // openRow: false,
      reportOpen: false,
      myItem: {
        date: moment().format("YYYY-MM-DD"),
        newDate: new Date(),
      },
      date: "",
      order: "asc",
      orderBy: "id",
      isLoading: false,
      Employechecked: false,
      is_select_card: "",
      EditTableModal: false,
      download: false,
      newStateAR: [],
      headerData: [],
      AprroveData: [],
      toolbarData: [
        {
          title: "summary",
          sortName: "summary",
          onClick: this.newModalOpen,
        },
        {
          title: "pe",
          sortName: "pe",
          onClick: this.clearModalOpen,
        },
        {
          title: "cash",
          sortName: "cash",
          onClick: this.notifyModalOpen,
        },
      ],
      errors: {},
      errorsHelper: {},
      usersData: [],
      newDataResUserData: [],
      dates: {},
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount = () => {
    if (this.props.permissionsData.scheduler_payroll_summary) {
      this.props.onChangePage();
      // var isReload = this.props.payrollWeeks.length > 0 ? false : true
      this.getHeaderData();
      this.getScheduleData();
    }
  };

  getHeaderData = () => {
    this.setState({
      isLoading: true,
    });
    var dataPayload = this.props.scheduler_lid;
    payrollSummaryServices.getTableHeaderData(dataPayload).then((response) => {
      if (response.data.HeaderData.length > 0) {
        var newObjTT = response.data.HeaderData.map((itemd) => {
          var objT = {
            id: itemd._id,
            content: itemd.header_name,
            numeric: false,
            status: itemd.status === 0 ? false : true,
            pi_id: itemd.pi_id,
          };
          return objT;
        });
        this.setState(
          {
            items: newObjTT,
            payrollIds: response.data.payroll_ids,
            // isLoading: false
          },
          () => {
            this.fetchData();
            this.getSettings();
          }
        );
      }
    });
  };
  getScheduleData = () => {
    var lid = this.props.scheduler_lid;
    this.props.getScheduleData(lid);
  };

  getSettings = () => {
    payrollSummaryServices
      .getSettingsData(this.props.scheduler_lid)
      .then((response) => {
        this.setState({
          Employechecked: response.data.result,
        });
      });
  };

  fetchData = (newData) => {
    var data = {
      lid: this.props.scheduler_lid,
      date: this.state.myItem.date,
    };
    payrollSummaryServices
      .fetchData(newData ? newData : data)
      .then((response) => {
        this.setState(
          {
            MonthData: response.data.result,
            is_select_card: response.data.result[0]?.date,
          },
          () => {
            this.getUserDetails(undefined, true);
          }
        );
      });
  };

  getUpdatedData = (selectedDate) => {
    let data = {
      lid: this.props.scheduler_lid,
      date: this.state.myItem.newDate,
    };
    payrollSummaryServices.fetchData(data).then((response) => {
      this.setState({
        MonthData: response.data.result,
        is_select_card: selectedDate,
      });
    });
  };

  showNewCard = (name) => {
    //var isReload = this.props.payrollWeeks.length > 0 ? false : true
    if (name === "Prev") {
      const NewdateConvert = new Date(this.state.myItem.newDate);
      const nextMonth = addMonths(NewdateConvert, 1);
      const formatDate = moment(nextMonth).format("YYYY-MM-DD");
      var data = {
        lid: this.props.scheduler_lid,
        date: formatDate,
      };
      this.fetchData(data);
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          newDate: formatDate,
        },
      }));
    }

    if (name === "Next") {
      const NewdateConvert = new Date(this.state.myItem.newDate);
      const nextMonth = addMonths(NewdateConvert, -1);
      const formatDate = moment(nextMonth).format("YYYY-MM-DD");
      var data = {
        lid: this.props.scheduler_lid,
        date: formatDate,
      };
      this.fetchData(data);
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          newDate: formatDate,
        },
      }));
    }
  };

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  // refreshButton = (dates) => {
  //   this.setState(
  //     {
  //       isLoading: true,
  //       resetloader: true,
  //     },
  //     () => {
  //       var data = {
  //         lid: this.props.scheduler_lid,
  //         date: this.state.date,
  //         pi_id: this.state.payrollIds,
  //         hideEmployee: 1,
  //         isRefresh: 1,
  //       };
  //       payrollSummaryServices.resetDataDetails(data).then((response) => {
  //         if (response.data.success === 2) {
  //         } else {
  //           var nameArr = dates.split(",");
  //           var data = {
  //             lid: this.props.scheduler_lid,
  //             payroll_startDate: nameArr?.[0],
  //             payroll_endDate: nameArr?.[1],
  //             date: dates,
  //           };
  //           payrollSummaryServices.resetApprovalData(data).then((res) => {
  //             if (res.data.success === 2) {
  //             } else {
  //               if (this.state.Employechecked) {
  //                 this.setState({
  //                   Activity_log: response.data.Activity_log,
  //                   AprroveData: response.data.AprroveData,
  //                   RefreshDate: response.data.RefreshDate,
  //                   payrollData: response.data.PayrollData,
  //                   usersData: response.data.usersData.filter(
  //                     (item) => item.total_paid_hrs != "0.00"
  //                   ),
  //                   newDataResUserData: response.data.usersData,
  //                   isLoading: false,
  //                   resetloader: false,
  //                   reportOpen: true,
  //                 });
  //               } else {
  //                 this.setState({
  //                   Activity_log: response.data.Activity_log,
  //                   RefreshDate: response.data.RefreshDate,
  //                   AprroveData: response.data.AprroveData,
  //                   payrollData: response.data.PayrollData,
  //                   usersData: response.data.usersData,
  //                   newDataResUserData: response.data.usersData,
  //                   isLoading: false,
  //                   resetloader: false,
  //                   reportOpen: true,
  //                 });
  //               }
  //               // alert.success(response.data.message)
  //               this.getUserDetails(dates);
  //               this.getScheduleData();
  //             }
  //           });
  //         }
  //       });
  //     }
  //   );
  // };

  getUserDetails = (dates, loading) => {
    this.setState(
      {
        reportsHeaderLoader: true,
        isLoading: loading ? true : false,
        resetloader: true,
        is_select_card: this.state.is_select_card,
        date: dates ? dates : this.state.MonthData[0]?.monthDate,
      },
      () => {
        var data = {
          lid: this.props.scheduler_lid,
          date: dates ? dates : this.state.MonthData[0]?.monthDate,
          pi_id: this.state.payrollIds,
          hideEmployee: 1,
          // isRefresh: 0,
        };
        payrollSummaryServices.fetchDetails(data).then((response) => {
          if (response.data.success === 2) {
          } else {
            if (this.state.Employechecked) {
              this.setState({
                Activity_log: response.data.result?.activityLog,
                AprroveData: response.data.result?.approvedData,
                RefreshDate: response.data.result?.refreshDate,
                payrollData: response.data.result?.payrollData,
                usersData: response.data.result?.userData.filter(
                  (item) => item.total_paid_hrs !== "0.00"
                ),
                newDataResUserData: response.data.result?.userData,
                isLoading: false,
                reportsHeaderLoader: false,
                resetloader: false,
                reportOpen: true,
              });
            } else {
              this.setState({
                Activity_log: response.data.result?.activityLog,
                AprroveData: response.data.result?.approvedData,
                RefreshDate: response.data.result?.refreshDate,
                payrollData: response.data.result?.payrollData,
                usersData: response.data.result?.userData,
                newDataResUserData: response.data.result?.userData,
                isLoading: false,
                reportsHeaderLoader: false,
                resetloader: false,
                reportOpen: true,
              });
            }
          }
        });
      }
    );
  };

  fetchDetailsAfterComment = (dates) => {
    this.setState({
      isLoading: true,
    });
    var data = {
      lid: this.props.scheduler_lid,
      date: dates ? dates : this.state.date,
      pi_id: this.state.payrollIds,
      hideEmployee: 1,
      // isRefresh: 0
    };
    payrollSummaryServices.fetchDetails(data).then((response) => {
      if (response.data.success === 2) {
      } else {
        if (this.state.Employechecked) {
          this.setState({
            Activity_log: response.data.result?.activityLog,
            AprroveData: response.data.result?.approvedData,
            RefreshDate: response.data.result?.refreshDate,
            payrollData: response.data.result?.payrollData,
            usersData: response.data.result?.userData.filter(
              (item) => item.total_paid_hrs != "0.00"
            ),
            newDataResUserData: response.data.result?.userData,
            isLoading: false,
          });
        } else {
          this.setState({
            Activity_log: response.data.result?.activityLog,
            AprroveData: response.data.result?.approvedData,
            RefreshDate: response.data.result?.refreshDate,
            payrollData: response.data.result?.payrollData,
            usersData: response.data.result?.userData,
            newDataResUserData: response.data.result?.userData,
            isLoading: false,
          });
        }
      }
    });
  };

  clickCard = (row) => {
    console.log("hjfbfjv", row);
    this.setState(
      {
        isLoading: true,
        is_select_card: row.date,
        date: row.monthDate,
      },
      () => {
        var data = {
          lid: this.props.scheduler_lid,
          date: row.monthDate,
          pi_id: this.state.payrollIds,
          hideEmployee: 1,
          // isRefresh: 0
        };
        payrollSummaryServices.fetchDetails(data).then((response) => {
          if (response.data.success === 2) {
          } else {
            if (this.state.Employechecked) {
              this.setState({
                Activity_log: response.data.result?.activityLog,
                AprroveData: response.data.result?.approvedData,
                RefreshDate: response.data.result?.refreshDate,
                payrollData: response.data.result?.payrollData,
                usersData: response.data.result?.userData.filter(
                  (item) => item.total_paid_hrs != "0.00"
                ),
                newDataResUserData: response.data.result?.userData,
                isLoading: false,
              });
            } else {
              this.setState({
                Activity_log: response.data.result?.activityLog,
                AprroveData: response.data.result?.approvedData,
                RefreshDate: response.data.result?.refreshDate,
                payrollData: response.data.result?.payrollData,
                usersData: response.data.result?.userData,
                newDataResUserData: response.data.result?.userData,
                isLoading: false,
              });
            }
          }
        });
      }
    );
  };

  openDownloadModal = () => {
    this.setState({
      downloadModal: true,
    });
  };

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  };

  handleChangeCheckBox = (event) => {
    this.setState({
      isLoading: true,
      Employechecked: event.target.checked === true ? 1 : 0,
    });
    var data = {
      lid: this.props.scheduler_lid,
      date: this.state.date,
      pi_id: this.state.payrollIds,
      hideEmployee: event.target.checked === true ? 1 : 0,
      // isRefresh: 0,
    };
    payrollSummaryServices.fetchDetails(data).then((response) => {
      if (response.data.success === 2) {
      } else {
        if (this.state.Employechecked) {
          this.setState({
            Activity_log: response.data.result?.activityLog,
            AprroveData: response.data.result?.approvedData,
            RefreshDate: response.data.result?.refreshDate,
            payrollData: response.data.result?.payrollData,
            usersData: response.data.result?.userData.filter(
              (item) => item.total_paid_hrs != "0.00"
            ),
            newDataResUserData: response.data.result?.userData,
            isLoading: false,
          });
        } else {
          this.setState({
            Activity_log: response.data.result?.activityLog,
            AprroveData: response.data.result?.approvedData,
            RefreshDate: response.data.result?.refreshDate,
            payrollData: response.data.result?.payrollData,
            usersData: response.data.result?.userData,
            newDataResUserData: response.data.result?.userData,
            isLoading: false,
          });
        }
      }
    });
    //var newFilter = this.state.newDataResUserData.filter((item) => item.total_paid_hrs != "0.00")
    //    {
    //     if()
    //         return item

    //     })
    //    {
    //     newFilter =
    //     return newFilter
    //    }

    // if (event.target.checked === false) {
    //     var newFilter = this.state.newDataResUserData

    // }
    // else{

    // }

    // this.setState({
    //     usersData: newFilter
    // })
  };

  handleChange = (event) => {
    this.setState({
      valueSeclect: event.target.value,
    });
  };

  handleChangeShowMenu = (e) => {
    var name = e.target.name;
    this.setState((prevState) => ({
      dates: {
        ...prevState.dates,
        [name]: e.target.checked,
      },
    }));
  };

  saveCloumnRow = (array) => {
    this.setState({
      items: array,
    });
    let dataPayload = {
      lid: this.props.scheduler_lid,
      headerArr: this.state.newStateAR,
    };
    payrollSummaryServices.getTableAd(dataPayload).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        this.setState({
          items: this.state.items,
        });
        // this.closeEditTableModal();
        // var dataPayload = this.props.lid;
        // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
        //     if (response.data.HeaderData.length > 0) {
        //         var newObjTT = response.data.HeaderData.map((itemd) => {
        //             var objT = {
        //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true
        //             }
        //             return objT
        //         })

        //         this.setState({
        //             items: newObjTT
        //         })
        //     }
        // })
        this.getUserDetails(this.state.date, false);
        // this.props.fetchData(this.props.scheduler_lid)
        alert.success(response.data.message);
      }
    });
    // this.closeEditTableModal();
  };

  closeEditTableModal = () => {
    this.setState({
      EditTableModal: false,
    });
  };

  openEditTableModal = () => {
    this.setState({
      EditTableModal: true,
    });
    // var dataPayload = this.props.lid
    // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
    //     if(response.data.HeaderData.length>0){
    //         var newObjTT = response.data.HeaderData.map((itemd) => {
    //             var objT = {
    //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true
    //             }
    //             return objT
    //         })
    //         this.setState({
    //             items: newObjTT
    //         })
    //     }
    // })
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Reorder the items array based on the drag and drop indices
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    // Create a new array with the updated items
    const updatedItems = items.map((option, index) => ({
      _id: option.id, // Ensure ID is part of the new object
      // header_name: option.content, // Use content as the name
      status: option.status === true ? 1 : 0,
      index: index,
    }));

    // Update the state with the new order of items
    this.setState({
      items,
      newStateAR: updatedItems,
    });
  }

  handleChangeChecked = (e, index) => {
    const a =
      this.state.items &&
      this.state.items.forEach((element) => {
        if (element.id === index) {
          element.status = e.target.checked;
        }
      });

    this.setState({
      item: a,
    });
    var dataase =
      this.state.items &&
      this.state.items.map((option, index) => {
        var newobjs = {
          _id: option.id,
          header_name: option.content,
          status: option.status === true ? 1 : 0,
          index: index,
        };
        return newobjs;
      });
    this.setState({
      newStateAR: dataase,
    });
  };

  onReportChanged = () => {
    this.setState({
      reportOpen: !this.state.reportOpen,
    });
  };

  render() {
    const {
      classes,
      payrollWeeks,
      permissionsData,
      timezone,
      rowsPerPage,
      pageNo,
    } = this.props;
    if (this.state.isLoading) return <LoadingData />;
    return permissionsData.scheduler_payroll_summary ? (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <DetailPayroll
            payrollWeeks={payrollWeeks}
            showNewCard={this.showNewCard}
            toolbarData={this.state.toolbarData}
            reportOpen={this.state.reportOpen}
            onReportAccordianChanged={this.onReportChanged}
            payrollLoadingg={this.state.payrollLoading}
            timezone={timezone}
            refreshButton={this.refreshButton}
            lid={this.props.scheduler_lid}
            MonthData={this.state.MonthData}
            getUpdatedData={this.getUpdatedData}
            getUserDetails={this.getUserDetails}
            reportsHeaderLoader={this.state.reportsHeaderLoader}
            clickCard={this.clickCard}
            Activity_log={this.state.Activity_log}
            RefreshDate={this.state.RefreshDate}
            AprroveData={this.state.AprroveData}
            payrollData={this.state.payrollData}
            usersData={this.state.usersData}
            newDataResUserData={this.state.newDataResUserData}
            payrollLoadingCard={this.state.isLoading}
            handleChangeCheckBox={this.handleChangeCheckBox}
            myItem={this.state.myItem}
            fetchDetailsAfterComment={this.fetchDetailsAfterComment}
            handleChange={this.handleChange}
            valueSeclect={this.state.valueSeclect}
            handleChangeShowMenu={this.handleChangeShowMenu}
            dates={this.state.dates}
            downloadData={this.downloadData}
            openDownloadModal={this.openDownloadModal}
            closeDownloadModal={this.closeDownloadModal}
            downloadModal={this.state.downloadModal}
            downloaduserdata={this.state.downloaduserdata}
            date={this.state.date}
            is_select_card={this.state.is_select_card}
            items={this.state.items}
            saveCloumnRow={this.saveCloumnRow}
            closeEditTableModal={this.closeEditTableModal}
            openEditTableModal={this.openEditTableModal}
            EditTableModal={this.state.EditTableModal}
            getScheduleData={this.getScheduleData}
            onDragEnd={this.onDragEnd}
            handleChangeChecked={this.handleChangeChecked}
            newStateAR={this.state.newStateAR}
            Employechecked={this.state.Employechecked}
            resetloader={this.state.resetloader}
          />
        </Grid>
        {/* {this.state.download &&
                    <ExportDownload
                    rowData ={this.state.usersData}
                     />
                    } */}
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerPayrollSummary.isLoading,
  payrollWeeks: state.schedulerPayrollSummary.payrollWeeks,
  MonthData: state.schedulerPayrollSummary.MonthData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  onChangePage,
  getScheduleData,
})(PayrollSummary);
export default withStyles(styles)(ConnectWith);
