import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from "../config";
import {
  Avatar,
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    minWidth: "230px",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
  },
  DialogTitle: {
    padding: "10px 20px",
    backgroundImage: schedulerColor.main,
    color: "white",
  },
  avatarText: {
    float: "left",
    textTransform: "uppercase",
    height: "45px",
    width: "45px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "#ffffff",
  },
  myTitle: {
    color: "white",
    fontSize: "14px",
    marginLeft: "5px",
    display: "inline",
    lineHeight: "2.5rem",
    float: "left",
    fontWeight: 600,
  },
  closeIcon: {
    padding: "5px",
    color: schedulerColor.main,
    cursor: "pointer",
    float: "right",
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    minHeight: "100px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  saveButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
  closeButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class MyRightModal extends Component {
  render() {
    const { classes, children, loading } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.DialogTitle}>
          {this.props.userImage ? (
            <Avatar src={this.props.userImage} className={classes.avatarText} />
          ) : (
            <Avatar className={classes.avatarText}>
              {this.props.userName.charAt(0)}
            </Avatar>
          )}

          <Typography className={classes.myTitle} variant="h4">
            {this.props.userName}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : (
            children
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={this.props.onClose}
            startIcon={<CloseIcon />}
            style={{ backgroundColor: "black" }}
            className={classes.closeButton}
            disabled={loading}
          >
            Close
          </Button>

          <Button
            style={{
              backgroundImage: this.props.bgColor ? this.props.bgColor : "gray",
            }}
            variant="outlined"
            size="medium"
            className={classes.saveButton}
            startIcon={<SaveIcon />}
            onClick={this.props.handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyRightModal);
